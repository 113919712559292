import VisibilityIcon from '@mui/icons-material/Visibility'
import { Box, InputAdornment } from '@mui/material'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import PropTypes from 'prop-types'
import React from 'react'

import CircularProgress from '@mui/material/CircularProgress'
import { makeStyles, withStyles } from '@mui/styles'
import { useSnackbar } from 'notistack'
import { useSafeSetState, useTranslate } from 'ra-core'
import { useState } from 'react'
import { Form, PasswordInput, required, TextInput, useDataProvider, useLogin } from 'react-admin'
import { useNavigate } from 'react-router-dom'
import authProvider from '../../config/authProvider'

// import '../../login-styles.css';

const useStyles = makeStyles((theme) => ({
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
    },
    icon: {
        marginRight: theme.spacing(1),
    },
}))

const styles = {
    login: {
        main: {
            background: 'none',
            height: 'auto',
            minHeight: 'inherit',
        },
        card: {
            boxShadow: 'none',
            marginTop: '0px',
            minWidth: '0px',
            width: '400px',
        },
        avatar: {
            display: 'none',
        },
    },
}

const imgSize = { width: '100%', height: '100%' }
const logHalf = {
    width: '50%',
    padding: '20px',
    margin: '25%',
}

const Login = (props) => {
    const navigate = useNavigate()
    const dataProvider = useDataProvider()
    const [loading, setLoading] = useSafeSetState(false)
    const login = useLogin()
    const translate = useTranslate()
    //
    const [loadingApteka, setLoadingApteka] = useState(false)
    const [loadingSeniomat, setLoadingSeniomat] = useState(false)
    const [usernameApteka, setUsernameApteka] = useState('')
    const [passwordApteka, setPasswordApteka] = useState('')
    const [usernameSeniomat, setUsernameSeniomat] = useState('')
    const [passwordSeniomat, setPasswordSeniomat] = useState('')
    const [showPasswordSeniomat, setShowPasswordSeniomat] = useState(false)
    const [showPasswordApteka, setShowPasswordApteka] = useState(false)

    const submitApteka = () => {
        const values = { username: usernameApteka.trim(), password: passwordApteka }
        setLoadingApteka(true)
        login(values)
            .then(() => {
                setLoadingApteka(false)
                dataProvider.getOne('pk_users', { id: `pk_users/me` }).then((resp) => {
                    let data = resp?.data
                    localStorage.setItem('partnerId', data?.partnerId)
                    enqueueSnackbar(`Witaj ${data?.firstName}. Twoje logowanie przebiegło poprawnie`, { variant: 'success' })
                })
            })
            .catch((error) => {
                setLoadingApteka(false)
                enqueueSnackbar('Niepoprawna nazwa użytkownika lub hasło', { variant: 'error' })
            })
    }

    const submitSeniomat = () => {
        const values = { username: usernameSeniomat.trim(), password: passwordSeniomat }
        setLoadingSeniomat(true)
        login(values)
            .then(() => {
                setLoadingSeniomat(false)

                enqueueSnackbar('Logowanie do Seniomat przebiegło pomyślnie', { variant: 'success' })
            })
            .catch((error) => {
                setLoadingSeniomat(false)
                enqueueSnackbar('Niepoprawna nazwa użytkownika lub hasło', { variant: 'error' })
            })
    }
    const handleTogglePasswordVisibilityApteka = () => {
        setShowPasswordApteka(!showPasswordApteka)
    }
    const handleTogglePasswordVisibilitySeniomat = () => {
        setShowPasswordSeniomat(!showPasswordSeniomat)
    }

    const validate = (values) => {
        const errors = { username: undefined, password: undefined }

        if (!values.username) {
            errors.username = translate('ra.validation.required')
        }
        if (!values.password) {
            errors.password = translate('ra.validation.required')
        }
        return errors
    }

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    return (
        <div>
            <link rel="stylesheet" href="/login-styles.css" />

            <Box>
                {/* <img src="./logimg.png" alt="TZMO Online" width="500" height="500" /> */}
                <div> </div>
                <Box className="col" style={{ marginTop: '40px' }}>
                    <div className="row w-100">
                        <div className="col-md-3"></div>

                        <div class="container">
                            <div class="left-content">
                                <Form
                                    defaultValues={{
                                        username: '',
                                        password: '',
                                    }}
                                >
                                    <div className="form-login">
                                        <Box height={220}>
                                            <h6>
                                                <strong>
                                                    <span className="TextLoggingOrange">Dla Aptek i Sklepów Medycznych</span>
                                                </strong>{' '}
                                            </h6>
                                            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                                                <img src="/img/logotzmoonline1.png" style={{ width: '212px', margin: '40px' }} />
                                            </div>
                                        </Box>
                                        <div className="f-group">
                                            <TextInput
                                                autoFocus
                                                source="usernameApteka"
                                                disabled={loadingApteka}
                                                value={usernameApteka}
                                                onChange={(e) => setUsernameApteka(e.target.value)}
                                                label="Login - adres e-mail"
                                                validate={required()}
                                                fullWidth={true}
                                                variant="outlined"
                                                className="loginName custom-blue-label"
                                                InputProps={{
                                                    classes: {
                                                        notchedOutline: 'custom-outline',
                                                    },
                                                }}
                                            />
                                        </div>
                                        <div className="f-group">
                                            <PasswordInput
                                                type={showPasswordApteka ? 'text' : 'password'}
                                                source="passwordApteka"
                                                label={translate('ra.auth.password')}
                                                value={passwordApteka}
                                                disabled={loadingApteka}
                                                onChange={(e) => setPasswordApteka(e.target.value)}
                                                validate={required()}
                                                fullWidth={true}
                                                variant="outlined"
                                                className="loginName custom-blue-label"
                                                InputProps={{
                                                    classes: {
                                                        notchedOutline: 'custom-outline',
                                                    },
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={handleTogglePasswordVisibilityApteka} edge="end">
                                                                {showPasswordApteka ? (
                                                                    <VisibilityOffIcon style={{ color: '#57A0CE' }} />
                                                                ) : (
                                                                    <VisibilityIcon style={{ color: '#57A0CE' }} />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                inputProps={{ style: { padding: '12px' } }}
                                            />
                                        </div>
                                        <div className="col" style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '8px' }}></div>
                                        <div className="f-group">
                                            <Button
                                                variant="contained"
                                                type="submit"
                                                disableElevation
                                                disabled={loadingApteka}
                                                onClick={submitApteka}
                                                sx={{
                                                    backgroundColor: '#FF7C34',
                                                    color: '#fff',
                                                    borderRadius: '30px',
                                                    width: '100%',
                                                    height: '50px',
                                                    fontSize: '16px',
                                                    textTransform: 'none',
                                                    '&:hover': {
                                                        backgroundColor: '#FF651A',
                                                    },
                                                }}
                                            >
                                                {loadingApteka && (
                                                    <CircularProgress
                                                        size={25}
                                                        thickness={2}
                                                        sx={{
                                                            svg: { color: '#FF7C34' },
                                                            marginRight: 2,
                                                        }}
                                                    />
                                                )}
                                                {translate('ra.auth.sign_in')}
                                            </Button>
                                        </div>
                                        <p className="logexpl">
                                            Jesli nie pamiętasz hasla, skorzystaj z funkcji resetowania hasla dostępnej{' '}
                                            <a href="#/reset_password" className="logexpl">
                                                tutaj
                                            </a>
                                        </p>{' '}
                                    </div>
                                </Form>
                            </div>

                            <div class="vertical-line"></div>

                            <div class="right-content">
                                <Form
                                    defaultValues={{
                                        username: '',
                                        password: '',
                                    }}
                                >
                                    <div className="form-login">
                                        <Box height={220}>
                                            <h6>
                                                <strong>
                                                    <span className="TextLoggingOrange">Dla Instytucji Opieki Długoterminowej</span>
                                                </strong>{' '}
                                            </h6>

                                            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                                                <img src="/img/senimat.png" style={{ width: '370px', height: 'auto', margin: '40px' }} />
                                            </div>
                                        </Box>
                                        <div className="f-group">
                                            <TextInput
                                                autoFocus
                                                source="usernameSeniomat"
                                                disabled={loadingSeniomat}
                                                value={usernameSeniomat}
                                                onChange={(e) => setUsernameSeniomat(e.target.value)}
                                                validate={required()}
                                                fullWidth={true}
                                                variant="outlined"
                                                className="loginName custom-blue-label"
                                                // className="loginName"
                                                label="Login - adres e-mail"
                                                InputProps={{
                                                    classes: {
                                                        notchedOutline: 'custom-outline',
                                                    },
                                                }}
                                            />
                                        </div>
                                        <div className="f-group">
                                            <PasswordInput
                                                type={showPasswordSeniomat ? 'text' : 'password'}
                                                source="passwordSeniomat"
                                                label={translate('ra.auth.password')}
                                                value={passwordSeniomat}
                                                disabled={loadingSeniomat}
                                                onChange={(e) => setPasswordSeniomat(e.target.value)}
                                                validate={required()}
                                                fullWidth={true}
                                                variant="outlined"
                                                className="loginName custom-blue-label"
                                                InputProps={{
                                                    classes: {
                                                        notchedOutline: 'custom-outline',
                                                    },
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={handleTogglePasswordVisibilitySeniomat} edge="end">
                                                                {showPasswordSeniomat ? (
                                                                    <VisibilityOffIcon style={{ color: '#57A0CE' }} />
                                                                ) : (
                                                                    <VisibilityIcon style={{ color: '#57A0CE' }} />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                inputProps={{ style: { padding: '12px' } }}
                                            />
                                        </div>
                                        <div className="col" style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '8px' }}></div>
                                        <div className="f-group">
                                            <Button
                                                variant="contained"
                                                disabled={loadingSeniomat}
                                                onClick={submitSeniomat}
                                                type="submit"
                                                disableElevation
                                                sx={{
                                                    backgroundColor: '#FF7C34',
                                                    color: '#fff',
                                                    borderRadius: '30px',
                                                    width: '100%',
                                                    height: '50px',
                                                    fontSize: '16px',
                                                    textTransform: 'none',
                                                    '&:hover': {
                                                        backgroundColor: '#FF651A',
                                                    },
                                                }}
                                            >
                                                {loading && (
                                                    <CircularProgress
                                                        size={25}
                                                        thickness={2}
                                                        sx={{
                                                            svg: { color: '#FF7C34' },
                                                            marginRight: 2,
                                                        }}
                                                    />
                                                )}
                                                {translate('ra.auth.sign_in')}
                                            </Button>
                                        </div>
                                        <p className="logexpl">
                                            Jesli nie pamiętasz hasla, skorzystaj z funkcji resetowania hasla dostępnej{' '}
                                            <a href="#/reset_password" className="logexpl">
                                                tutaj
                                            </a>
                                        </p>{' '}
                                    </div>
                                </Form>
                            </div>
                        </div>

                        <div className="col"></div>
                    </div>

                    <div class="footer-content">
                        <p class="footer-title">
                            <strong>ZAMÓWIENIA REALIZOWANE SĄ PRZEZ:</strong>
                        </p>
                        <div class="image-row">
                            <img src="/img/logo_tzmo_sa_.png" class="footerImg" />
                            <img src="/img/logo-ines.png" class="footerImg" />
                            <img src="/img/logocitonet-kr.png" class="footerImg" />
                            <img src="/img/logocitonet-lodz.png" class="footerImg" />
                            <img src="/img/citonet-szc.png" class="footerImg" />
                            <img src="/img/farmpol.png" class="footerImg" />
                            <img src="/img/smp.png" class="footerImg" />
                            <img src="/img/logo_cito2.png" class="footerImg" />
                            <img src="/img/logotyp-DT.png" class="footerImg" />
                        </div>
                    </div>

                    <div class="bottom-text-wrapper">
                        <p class="bottomTextLogin">
                            Jeśli masz problem z logowaniem, skontaktuj się z nami:{' '}
                            <a href="tel:56 612 38 00" className="logexpl">
                                56 612 38 00 wew.1
                            </a>
                            ,
                            <a href="mailtto:bok@tzmo-global.com" className="logexpl">
                                bok@tzmo-global.com
                            </a>{' '}
                            pracujemy w godzinach 8-16 pn-pt{' '}
                        </p>
                    </div>
                </Box>
            </Box>
        </div>
    )
}

Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
}

export default withStyles(styles.login)(Login)
